<template>
  <div>
    <h3 class="p-b-10">
      <label class="title m-r-5">创建时间</label>
      {{approval.title}}<span
        style="color:#ee7c4b"
        class="m-r-5"
        v-show="approval.createTime"
      > {{approval.createTime}}</span>
      <Tag
        color="#44bd32"
        v-if="approval.status===5"
      > {{approval.statusName}}</Tag>
      <Tag
        color="#ef4f4f"
        v-else-if="approval.status===6||approval.status===2"
      > {{approval.statusName}}</Tag>
      <Tag
        color="#a59426"
        v-else-if="approval.status===4"
      > {{approval.statusName}}</Tag>
      <Tag
        color="#a59426"
        v-if="approval.supply === 1"
      > 待补充</Tag>

      <div class="right">
        <Button
          type="text"
          size="small"
          class="m-r-5"
          style="background:#ef4f4f"
          v-if="approval.status===4"
          @click="handleWithdraw"
        >撤回审批</Button>
        <!-- 审批完毕后， 是待补充状态 -->
        <Button
          type="primary"
          size="small"
          class="m-r-5"
          v-if="approval.supply === 1 && approval.status === 5"
          @click="handleSupply"
        >补充文件</Button>
        <!-- 已拒绝或者草稿（已中止），允许重新编辑画面 -->
        <Button
          type="primary"
          size="small"
          class="m-r-5"
          v-if="approval.status === 0 || approval.status === 6"
          @click="handleResetApproval"
        >重新编辑审批内容</Button>
      </div>
    </h3>
    <p v-if="uploadDesc !== ''">
      <label class="title m-r-5">上传备注</label>{{uploadDesc}}
    </p>

    <Card
      v-if="approvalImage.length"
      dis-hover
    >
      <p slot="title">画面</p>
      <Row :gutter="8">
        <i-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="(image,imgIndex) in approvalImage"
          :key="imgIndex"
        >
          <a
            href="javascript:;"
            @click="handlePreview(image.fileKey)"
          >
            <img
              :src="formatImageUrl(image.fileKey,200,0)"
              style="width:100%"
            >
          </a>
        </i-col>
        <i-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-if="approvalImage.length===0"
        >
          <span>暂无可用画面</span>
        </i-col>
      </Row>
    </Card>

    <Card
      class="m-t-10"
      v-if="approvalVideo.length"
      dis-hover
    >
      <p slot="title">视频</p>
      <Row :gutter="8">
        <i-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="(video,videoIndex) in approvalVideo"
          :key="videoIndex"
        >
          <card
            class="text-center"
            style="word-break: break-all;word-wrap: break-word;"
          >
            <a @click="handleVideoPreview(video.fileKey)">{{video.fileName}}</a> <span class="text-green">({{video.duration}}秒)</span>
          </card>
        </i-col>
      </Row>
    </Card>
    <Card
      class="m-t-10"
      v-if="approvalVoice.length"
      dis-hover
    >
      <p slot="title">音频</p>
      <Row :gutter="8">
        <i-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="(voice,voiceIndex) in approvalVoice"
          :key="voiceIndex"
        >
          <card
            class="text-center"
            style="word-break: break-all;word-wrap: break-word;"
          >
            <a @click="handleVideoPreview(voice.fileKey)">{{voice.fileName}}</a> <span class="text-green">({{voice.duration}}秒)</span>
          </card>
        </i-col>
      </Row>
    </Card>

    <Card
      class="m-t-10"
      dis-hover
    >
      <p slot="title">资质文件列表</p>
      <i-table
        stripe
        :columns="approvalFileColumn"
        :data="approvalFile"
        size="small"
      ></i-table>
    </Card>

    <Card
      class="m-t-10"
      dis-hover
    >
      <p slot="title">审批记录</p>

      <template v-if="nodes.length>0">
        <span class="workplatform-title">审批进度</span>
        <Steps :current="currentStep">
          <Step
            v-for="(node,i) in nodes"
            :key="i"
            :title="node.approvalnodeName"
            :content="node.approverUserName + '['+ node.statusName + ']'"
          >
          </Step>
        </Steps>
      </template>

      <span class=" workplatform-title">已有审批记录</span>
      <i-table
        stripe
        :columns="titles"
        :data="records"
        size="small"
      ></i-table>

      <template v-if="withdrawRecords.length>0">
        <span class=" workplatform-title">撤回审批记录</span>
        <i-table
          stripe
          :columns="withdrawTitles"
          :data="withdrawRecords"
          size="small"
        ></i-table>
      </template>
    </Card>

    <!-- 上刊材料预览（画面、视频） -->
    <Modal
      title="画面预览"
      v-model="visibleImage"
      width="60%"
      :footer-hide="true"
    >
      <img
        :src="ImageUrl"
        v-if="visibleImage"
        style="width: 100%"
      >
    </Modal>
    <Modal
      title="音视频预览"
      v-model="visibleVideo"
      :footer-hide="true"
    >
      <video
        :src="VideoUrl"
        id="materialHistoryModalVideo"
        controls="controls"
        style="width:100%;min-height:150px;"
      >
        您的浏览器不支持预览音视频。
      </video>
    </Modal>
    <!-- 补充上刊材料 -->
    <Modal
      title="补充画面材料资质"
      width="800"
      v-model="modelSupply"
      :footer-hide="true"
    >
      <material-upload
        :orderId="orderId"
        :relatedId="approval.id"
      ></material-upload>
    </Modal>
    <!-- 重新编辑上刊材料 -->
    <Modal
      width="800"
      v-model="modelResetApproval"
      footer-hide
      :closable="false"
      :styles="{top: '20px'}"
    >
      <Row slot="header">
        <i-col span="12">
          <h2>编辑上刊材料资质</h2>
        </i-col>
        <i-col
          span="12"
          class="text-right"
        >
          <Button
            type="primary"
            size="small"
            @click="submitResetApproval"
          >保存并提交</Button>
        </i-col>
      </Row>

      <div>
        <h4>上传备注：</h4>
        <Input
          v-model="updateDesc"
          placeholder="请填写审批备注信息"
        />
      </div>

      <Row
        :gutter="16"
        class="p-t-10"
      >
        <i-col span="12">
          <h4>已上传画面/视频/音频</h4>
          <i-table
            stripe
            :columns="editApprovalImgOrVideoColumn"
            :data="edieApprovalImgOrVideo"
            size="small"
          ></i-table>
        </i-col>
        <i-col span="12">
          <h4>已上传资质文件</h4>
          <i-table
            stripe
            :columns="editApprovalFileColumn"
            :data="editApprovalFile"
            size="small"
          ></i-table>
        </i-col>
      </Row>

      <Divider dashed><span class="text-orange">上传新的材料资质</span></Divider>
      <material-upload
        ref="materialUpload"
        :orderId="orderId"
        :relatedId="approval.id"
        isEdit
        @watchUploadSuccess="supplyUploadSuccess"
      ></material-upload>
    </Modal>
  </div>
</template>

<script>
import MaterialUpload from './MaterialUpload'
import { downloadFileRequest, downloadImgRequest } from '@/utils/download'
import { formatApprovalResult } from '@/utils/tagStatus'

import { fileByApproval, getApproval, terminateprocess } from '@/api/order/orderfile'
import { getApprovalSchedule } from '@/api/approval/approvaltask'
import { deleteFile, getOrderProcessType } from '@/api/order/orderfileV2'

export default {
  components: {
    MaterialUpload
  },
  data () {
    return {
      orderId: this.$store.state.installSetting.demandOrderId,
      // approval: this.$store.state.installSetting.approvalFile,
      approvalImage: [],
      approvalVideo: [],
      approvalFile: [],
      approvalVoice: [],
      visibleImage: false,
      ImageUrl: '',
      visibleVideo: false,
      VideoUrl: '',
      withdrawRemark: '', // 撤回审批理由
      approvalFileColumn: [
        { title: '文件名称', key: 'fileName' },
        {
          title: '下载',
          width: 70,
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }

              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      records: [],
      titles: [
        { title: '审批人', width: 100, key: 'approverUserName' },
        { title: '审批时间', key: 'finishTime' },
        {
          title: '审批意见',
          render: (h, data) => {
            let isWarning
            if (data.row.processtype === 10 && data.row.taskSupplementFeature === 1) {
              isWarning = h('tag', { props: { color: 'blue', type: 'border' } }, data.row.taskSupplementFeatureName)
            }

            return h('div', [
              formatApprovalResult(h, data.row.approvalResult, data.row.approvalResultName),
              isWarning
            ])
          }
        },
        { title: '审批备注', key: 'comment' }
      ],
      withdrawRecords: [],
      withdrawTitles: [
        { title: '处理人', key: 'latestApproverUserName' },
        { title: '撤回时间', key: 'latestApproverFinishTime' },
        { title: '撤回理由', key: 'comment' }
      ],
      currentStep: 0,
      nodes: [], // 所有的审批节点
      uploadDesc: '',
      modelSupply: false,

      modelResetApproval: false,
      updateDesc: '', // 新的备注信息
      deleteFileIds: [], // 删除的文件ID清单
      editApprovalFile: [],
      editApprovalFileColumn: [
        { title: '文件名称', key: 'fileName' },
        {
          title: '操作',
          width: 70,
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.deleteFileIds.push(params.row.id)
                  this.handleDelFile(params.row, 2)
                }
              }
            }, '删除')
          }
        }
      ],
      edieApprovalImgOrVideo: [],
      editApprovalImgOrVideoColumn: [
        { title: '文件名称', key: 'fileName' },
        {
          title: '操作',
          width: 70,
          render: (h, params) => {
            return h('a', {
              style: { color: '#ef4f4f' },
              on: {
                click: () => {
                  this.deleteFileIds.push(params.row.id)
                  this.handleDelFile(params.row, 1)
                }
              }
            }, '删除')
          }
        }
      ]
    }
  },
  computed: {
    approval () {
      return this.$store.state.installSetting.approvalFile
    }
  },
  methods: {
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v1/orderfile/downloadfile', { fileId: file.id }, file.fileName)
    },
    getApprovalDetails () {
      const _this = this
      _this.approvalImage = []
      _this.approvalVideo = []
      _this.approvalFile = []
      _this.approvalVoice = []

      fileByApproval({ approvalId: _this.approval.id }).then(res => {
        res.forEach(file => {
          if (file.type === 1) {
            _this.approvalImage.push(file)
          } else if (file.type === 2) {
            _this.approvalFile.push(file)
          } else if (file.type === 3) {
            _this.approvalVideo.push(file)
          } else if (file.type === 4) {
            _this.approvalVoice.push(file)
          }
        })
      })

      getApproval({ approvalId: _this.approval.id }).then(res => {
        this.uploadDesc = res.desc
        this.updateDesc = res.desc
      })
    },
    formatImageUrl (fileUrl, width, height) {
      const widthstr = (width === 0) ? '' : ',w_' + width
      const heightstr = (height === 0) ? '' : ',h_' + height

      return fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
    },
    handlePreview (fileUrl) {
      this.ImageUrl = fileUrl
      this.visibleImage = true
    },
    formatImgUrl (url, imgElementId) {
      return downloadImgRequest(url, imgElementId)
    },
    handleVideoPreview (url) {
      document.getElementById('materialHistoryModalVideo').load()
      this.VideoUrl = url
      this.visibleVideo = true
    },
    getProcessDetail () {
      var node = getApprovalSchedule({
        processtype: this.processtype,
        relateId: this.approval.id,
        includeHistoryProcessinstance: false
      }).then(data => {
        this.nodes = data
      })
      var record = getApprovalSchedule({
        processtype: this.processtype,
        relateId: this.approval.id,
        includeHistoryProcessinstance: true
      }).then(res => {
        this.records = res.filter(item => item.status && item.status === 1)
        this.withdrawRecords = res.filter(item => item.status && item.status === 2)
      })

      Promise.all([node, record]).then(() => {
        var lastRecord = this.records[this.records.length - 1] || {}
        this.currentStep =
          this.nodes.findIndex(
            node => node.approvalnodeId === lastRecord.approvalnodeId
          ) + 1
      })
    },
    // 撤回审批
    handleWithdraw () {
      this.withdrawRemark = ''
      this.$Modal.confirm({
        render: (h) => {
          return h('Input', {
            props: {
              value: this.withdrawRemark,
              autofocus: true,
              placeholder: '请填写撤回审批理由'
            },
            on: {
              input: (val) => {
                this.withdrawRemark = val
              }
            }
          })
        },
        onOk: () => {
          if (this.withdrawRemark.trim().length <= 0) {
            this.$Notice.warning({ desc: '请填写撤回审批理由' })
            return false
          }
          const postData = {
            desc: this.withdrawRemark,
            approvalId: this.approval.id
          }
          terminateprocess(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '撤回审批操作成功' })
              this.$store.dispatch('getApprovalFileHistory', { orderId: this.orderId })
            } else {
              this.$Notice.error({ title: '撤回审批操作失败', desc: res.errmsg })
            }
          })
        }
      })
    },
    handleSupply () {
      this.modelSupply = true
    },
    supplyUploadSuccess () {
      // 更新父窗体，获取最新的数据
      this.modelResetApproval = false
    },
    handleResetApproval () {
      this.modelResetApproval = true

      this.deleteFileIds = []
      this.editApprovalFile = this.approvalFile.map(x => Object.assign({}, x))

      this.edieApprovalImgOrVideo = this.approvalImage.map(x => Object.assign({}, x))
      this.edieApprovalImgOrVideo = this.edieApprovalImgOrVideo.concat(this.approvalVideo.map(x => Object.assign({}, x)), this.approvalVoice)
    },
    submitResetApproval () {
      const editBean = {
        approvalId: this.approval.id,
        desc: this.updateDesc,
        deleteFileIds: this.deleteFileIds
      }

      this.$refs.materialUpload.confirmUpload(editBean)
    },
    async handleDelFile (file, fileType) {
      const result = await deleteFile({ fileId: file.id, orderId: this.orderId })
      if (result && result.success) {
        this.$Notice.success({ desc: '删除成功' })
        if (fileType === 1) {
          this.edieApprovalImgOrVideo.splice(file._index, 1)
        } else {
          this.editApprovalFile.splice(file._index, 1)
        }
      }
    }
  },
  created () {
    if (this.approval.id) {
      // 加载画面审批类型
      getOrderProcessType({ orderId: this.orderId }).then(res => {
        this.processtype = res
        this.getProcessDetail()
      })
      this.getApprovalDetails()
    }
  },
  watch: {
    approval: function () {
      if (this.approval.id) {
        // 加载画面审批类型
        getOrderProcessType({ orderId: this.orderId }).then(res => {
          this.processtype = res
          this.getProcessDetail()
        })
        this.getApprovalDetails()
      }
    }
  }
}
</script>
